<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true">
				<span>
					<el-form-item label="期数">
						<el-select v-model="form.qishu" placeholder="请选择期数" clearable>
							<el-option v-for="(item,index) in qishuList" :key="index" :label="item.qishu" :value="item.qishu"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>
		<p v-if="nowQishu.result">期数：{{nowQishu.qishu}}，开奖结果：{{nowQishu.result}},<br/>14场一等奖奖金：{{nowQishu.first_prize}}，中奖人数：{{nowQishu.first_count}}，二等奖奖金：{{nowQishu.sec_prize}}，中奖人数：{{nowQishu.sec_count}}，<br/>任9一等奖奖金：{{nowQishu.random9_prize}}，中奖人数：{{nowQishu.random9_count}}</p>
		<p v-else>期数：{{nowQishu.qishu}}，未开奖</p>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="ID" width="60" align="center">
			</el-table-column>
			<el-table-column prop="qishu" label="期数" width="80" align="center">
			</el-table-column>
			<el-table-column prop="game_name" label="比赛类型" width="80" align="center">
			</el-table-column>
			<el-table-column prop="zhudui_name" label="主队" align="center">
			</el-table-column>
			<el-table-column prop="kedui_name" label="客队" align="center">
			</el-table-column>
			<el-table-column label="比赛状态" width="100" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status == '0'">未开始可投注</span>
					<span v-if="scope.row.status == '10'">未开始截止投注</span>
					<span v-if="scope.row.status == '20'">上半场</span>
					<span v-if="scope.row.status == '30'">中场休息</span>
					<span v-if="scope.row.status == '40'">下半场</span>
					<span v-if="scope.row.status == '50'">比赛结束</span>
					<span v-if="scope.row.status == '99'">比赛腰斩</span>
					<span v-if="scope.row.status == '102'">比赛改期</span>
					<span v-if="scope.row.status == '110'">官方确定延期</span>
				</template>
			</el-table-column>
			<el-table-column prop="match_turn" label="竞猜轮次" width="80" align="center">
			</el-table-column>
			<el-table-column prop="start_time" label="开始时间" width="160" align="center">
			</el-table-column>
			<el-table-column prop="single_double_sp" label="赛果" width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.zhujin > scope.row.kejin">胜</span>
					<span v-else-if="scope.row.zhujin == scope.row.kejin">平</span>
					<span v-else>负</span>
				</template>
			</el-table-column>
			<el-table-column label="赔率" align="center">
				<el-table-column prop="win_odds" label="胜" width="70" align="center">
				</el-table-column>
				<el-table-column prop="draw_odds" label="平" width="70" align="center">
				</el-table-column>
				<el-table-column prop="lose_odds" label="负" width="70" align="center">
				</el-table-column>
			</el-table-column>	
			<el-table-column prop="game_name" label="操作" align="center">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="goModify(scope.row)">修改数据</el-button>
					<el-button v-if="scope.row.status == 50" type="primary" plain size="small" @click="setReopen(scope.row.id)">重新开奖</el-button>
				</template>
			</el-table-column>			
		</el-table>		
		<el-dialog title="修改比赛数据" :visible.sync="dialogShow" width="760px" center>
			<h2 class="tc mb15">{{gameInfo.zhudui_name}} Vs {{gameInfo.kedui_name}}</h2>
			<el-form :model="gameInfo" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="比赛状态">
							<el-select v-model="gameInfo.status" placeholder="请选择状态" clearable>
								<el-option v-for="(item,index) in statusList" :key="index" :label="item.value" :value="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="半场赛果">
							<el-select v-model="gameInfo.half" placeholder="请选择状态" clearable>
								<el-option v-for="(item,index) in halfList" :key="index" :label="item.value" :value="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="完场赛果">
							<el-select v-model="gameInfo.whole" placeholder="请选择状态" clearable>
								<el-option v-for="(item,index) in halfList" :key="index" :label="item.value" :value="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="主队进球">
							<el-input type="number" min="0" v-model="gameInfo.zhujin" placeholder="20个字符" class="w1"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="客队进球">
							<el-input type="number" min="0" v-model="gameInfo.kejin" placeholder="20个字符" class="w1"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="赛果确认次数">
							<el-input type="number" v-model="gameInfo.match_result_count" placeholder="20个字符" class="fl w1 mr10"></el-input>
							<p class="fl yellow">确认次数大于等于2，则数据比对成功，可开奖。 </p>
						</el-form-item>						
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="goEdcDialog()">提 交</el-button>
				<el-button @click="dialogShow = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog title="确认修改数据" :visible.sync="edcDialog" width="560px" center>
			<h2 class="mb15">{{gameInfo.zhudui_name}} Vs {{gameInfo.kedui_name}}的比赛修改数据如下：</h2>
			<el-form :model="gameInfo" label-width="120px">
				<el-row class="mb10">
					<el-col :span="12">					
						<p>比赛状态：
							<strong v-if="gameInfo.status == '0'">未开始可投注</strong>
							<strong v-if="gameInfo.status == '10'">未开始截止投注</strong>
							<strong v-if="gameInfo.status == '20'">上半场</strong>
							<strong v-if="gameInfo.status == '30'">中场休息</strong>
							<strong v-if="gameInfo.status == '40'">下半场</strong>
							<strong v-if="gameInfo.status == '50'">比赛结束</strong>
							<strong v-if="gameInfo.status == '99'">比赛腰斩</strong>
							<strong v-if="gameInfo.status == '102'">比赛改期</strong>
							<strong v-if="gameInfo.status == '110'">官方确定延期</strong>
						</p>
					</el-col>
				</el-row>
				<el-row class="mb10">
					<el-col :span="12">
						<p>半场赛果：<strong v-if="gameInfo.half == 3">胜</strong>
					<strong v-if="gameInfo.half == 1">平</strong>
					<strong v-if="gameInfo.half == 0">负</strong></p>
					</el-col>
					<el-col :span="12">
						<p>完场赛果：<strong v-if="gameInfo.whole == 3">胜</strong>
					<strong v-if="gameInfo.whole == 1">平</strong>
					<strong v-if="gameInfo.whole == 0">负</strong></p>
					</el-col>
				</el-row>
				<el-row class="mb10">
					<el-col :span="12">
						<p>主队进球：<strong>{{gameInfo.zhujin}} 球</strong></p>
					</el-col>
					<el-col :span="12">
						<p>客队进球：<strong>{{gameInfo.kejin}} 球</strong></p>
					</el-col>
				</el-row>
				<el-row class="mb10">
					<el-col :span="24">
						<p>赛果确认次数：<strong>{{gameInfo.match_result_count}}</strong></p>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="setModify()">提 交</el-button>
				<el-button @click="goDialogShow()">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import {
		zcList,qishu,modify,reopen
	} from '../../api/api';

	export default {
		data() {
			return {
				listData: [],
				form:{
					qishu:'',
					lottery_type:'3'
				},
				nowQishu:{},
				qishuList:[],
				statusList:[
					{
						value:'未开始可投注',
						label: 0,
					},{
						value:'未开始截止投注',
						label: 10,
					},{
						value:'上半场',
						label: 20,
					},{
						value:'中场休息',
						label: 30,
					},{
						value:'下半场',
						label: 40,
					},{
						value:'比赛结束',
						label: 50,
					},{
						value:'比赛腰斩',
						label: 99,
					},{
						value:'比赛改期',
						label: 102,
					},{
						value:'官方确定延期',
						label: 110,
					}
				],
				halfList:[
					{
						value:'胜',
						label: '3',
					},{
						value:'平',
						label: '1',
					},{
						value:'负',
						label: '0',
					}
				],
				gameInfo:{},
				dialogShow: false,
				edcDialog: false,
				listLoading: false,
			}
		},
		// watch:{
		// 	'form.qishu': function(newValue,oldVale){
		// 		console.log(newValue)
		// 		if(newValue != oldVale){
		// 			this.qishuList.some(item =>{
		// 				if(item.qishu == newValue){
		// 					this.nowQishu = item
		// 				}
		// 			})					
		// 		}
		// 	}
		// },
		methods: {
			goDialogShow(){
				this.edcDialog = false;
				this.dialogShow = true
			},
			goEdcDialog(){
				this.edcDialog = true;
				this.dialogShow = false
			},
			goModify(data){
				this.gameInfo = this.deepCopy(data);
				this.dialogShow = true
			},
			// 修改数据
			setModify(){				
				var _t = this;
				_t.edcDialog = false;
				let para = {
					id: _t.gameInfo.id,
					status: _t.gameInfo.status,
					half: _t.gameInfo.half,
					whole: _t.gameInfo.whole,
					zhujin: _t.gameInfo.zhujin,
					kejin: _t.gameInfo.kejin,
					match_result_count: _t.gameInfo.match_result_count,
					lottery_type:'3'
				};
				modify(para).then((res) => {
					if (res.code == 1) {
						_t.getList();
					} else {
						_t.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			// 重新开奖
			setReopen(id){				
				var _t = this;
				let para = {
					id: id,
					lottery_type:'1'
				};
				reopen(para).then((res) => {
					if (res.code == 1) {
						_t.$notify({
							title: '重置成功'
						});
					} else {
						_t.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//获取数据
			getList(val) {
				var _t = this;
				if (val == '0') _t.page = 1
				let para = {
					..._t.form
				};
				_t.listLoading = true;
				zcList(para).then((res) => {
					if (res.code == 1) {
						_t.total = res.data.count;
						_t.listData = res.data;
						_t.listLoading = false;
						_t.qishuList.some(item =>{
							if(item.qishu == _t.form.qishu){
								_t.nowQishu = item
							}
						})
					} else {
						_t.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			getQishu(){
				qishu({lottery_type:'3'}).then((res) => {
					if (res.code == 1) {
						this.nowQishu = res.data[1];
						this.form.qishu = res.data[0].qishu;
						this.qishuList = res.data;
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},			
		},
		mounted() {
			//获取列表数据
			qishu({lottery_type:'3'}).then((res) => {
				if (res.code == 1) {
					this.nowQishu = res.data[0];
					this.form.qishu = res.data[0].qishu;
					this.qishuList = res.data;
					this.getList();
				} else {
					this.$notify({
						title: '错误',
						message: res.msg,
						type: 'error'
					});
				}
			});
			
		}
	}
</script>

<style lang="scss" scoped>
	.w1{ width:217px }
</style>
